import React from 'react'
//import { Link } from 'gatsby'
// Team from '../images/teamCork.jpg'
import Layout from '../components/layout'
import Team from '../images/teamCork.jpg'
import Dublin from '../images/dublinWin.jpg'
import Barrow from '../images/barrowRun.jpg'

const Achievements = () => (
  <Layout>
    <h1>2018 Was A Successful Year For The Waterford Dragon Warriors!</h1>
    <h3>Cork Regatta August 2018</h3>
    <p>
        We had 2 teams in the Cork Regatta. We managed to have one team in the semi-final of the cup
        and both teams in the final of the plate! A great achievement and, either way, the plate was coming 
        home to Waterford!
    </p>
    <img  src={Team} alt="dragon team"/>
    <br></br>
    <h3>Dublin Hong Kong International Dragon Boat Regatta September 2018</h3>
    <p>
        We entered our team in to the Open Challenge category at the Dublin Hong Kong International Dragon Boat Regatta.
        We had 3 initial races where we came second, first and second. This results and the times we had set put us into the final
        against Donegal Dragons, Carlow Crusaders and Joey's Splashers. We won the final with a time of 59:55.

    </p>
    <img src={Dublin} alt="dublin team"/>
    <br></br>
    <h3>The Barrow Challenge September 2018</h3>
    <p>
        Waterford Dragon Warriors entered a team to paddle the river Barrow from St. Mullins
        to New Ross Boat Club. It was 2 hours of paddling in stunning scenery with a beautiful 
        misty morning to greet us at St. Mullins. 

    </p>
    <img src={Barrow} alt="barrow team"/>
  </Layout>
)

export default Achievements
